
export const formatarValor = (value: any) => {

    var newValue = value;
    

    newValue = newValue + '';
    newValue = parseInt(newValue.replace(/[\D]+/g,''));
    if (isNaN(newValue))
    {
        return `R$ 0,00`;
    }
    newValue = newValue + '';
    newValue = newValue.replace(/([0-9]{2})$/g, ",$1");


    if (newValue.length > 6)
    {
        newValue = newValue.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    newValue = `R$ ${newValue}`;

    return newValue;

};

export const formatarPercentage = (value: any) => {
    
    var newValue = value;
    newValue = newValue + '';
    newValue = parseInt(newValue.replace(/[\D]+/g,''));
    if (isNaN(newValue))
    {
        return `0,00 %`;
    }

    newValue = newValue + '';
    newValue = newValue.replace(/([0-9]{2})$/g, ",$1");

    if (newValue.length > 6)
    {
        newValue = newValue.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    newValue = `${newValue} %`;

    return newValue;

};