import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 


interface ISliceTasks {
    [key: string]: any;
}


const initialState: ISliceTasks = {};

const SliceTasks = createSlice({
    name: 'formSliceTasks',
    initialState,
    reducers: {
        setSliceTasks: (
            state,
            action: PayloadAction<ISliceTasks>
        ) => {

            const sliceTasksPayload = action.payload;
            Object.keys(sliceTasksPayload).forEach((key) => {
                state[key] = sliceTasksPayload[key];
            });

        },
    },
});

export const { setSliceTasks } = SliceTasks.actions;
export default SliceTasks.reducer;
