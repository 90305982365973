import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import { Acordos } from '../pages/receita/Acordos'
import { PaymentsWrapper } from '../pages/pagamentos/PaymentsWrapper'
import { AnticipationWrapper } from '../../_metronic/partials/payments/parcel/AnticipationWrapper'
import { getToken } from '../redux/api/auth/getToken'
import { useFetchClientQuery } from '../redux/api/client/getClient'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  
//   const  dataLocalStorage = getToken();
//   useFetchClientQuery(dataLocalStorage?.token || '');

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Taregas after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/tasks' />} />
                
                {/* <Route path='documentation/:docs/*' element={<PrivateRoutesDocumentation />} /> */}
                {/* Pages */}
                

                {/* pagamentos */}
                <Route
                    path='tasks'
                    element={
                    <SuspensedView>
                        <PaymentsWrapper />
                    </SuspensedView>
                    }
                />

                <Route
                    path='antecipation'
                    element={
                    <SuspensedView>
                        <AnticipationWrapper />
                    </SuspensedView>
                    }
                />

                
                {/* <Route
                    path='payments/parcel/:id/*'
                    element={
                    <SuspensedView>
                        <ParcelDetailsWrapper />
                    </SuspensedView>
                    }
                /> */}
                {/* End payments */}

                {/* begin configuracao */}
                
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
