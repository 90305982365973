import React, { useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers"; 
import Select from "react-select";
import { useAuth } from "../../../../app/modules/auth";
import * as Yup from 'yup'
import { SchemaAntecipation } from "../schemas/SchemaAntecipation";
import { useMemo } from 'react';
import {  formatarValor } from "../../../helpers/functions/numbers";
import Swal from "sweetalert2";
import DateRangePickerComponent from "../../../layout/components/dates/TwoDates";
import { FormPropsContract } from "../../../../app/interface/I_Contract";
// import { useLazyFetchAgreementContractQuery } from "../../../../app/redux/api/contract/agreementContract";
import { SchemaAgreement } from "../schemas/SchemaAgreement";
import { useLazyFetchSimulationQuery } from "../../../../app/redux/api/parcel/simulation";
import { setSliceTasks } from "../../../../app/redux/slices/actionSliceTasks";
import { useDispatch } from "react-redux";

export const FormAgreement: React.FC<FormPropsContract> = ({ 
    formValues,
    idContract,
    openDraweSimulation,
    closeModal,    
    onFormSubmit
}) => {    
    
    const {auth, logout, currentUser} = useAuth();  
    const dispatch = useDispatch(); 

    const [formData, setFormData] = useState({
        id_contract: idContract,
        date: '',
        justification: '',
        form_payment:'boleto',
        value: '',
        value_juros: '',
        value_contraproposta: '',
        name_client: currentUser?.name || '',
        client: formValues?.client || '',
        name_contract: formValues?.name_contract || '',
        name_unit: formValues?.name_unit || '',
        action:'agreement',
    });
    
    const [fetchSimulation, results] = useLazyFetchSimulationQuery()

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({        
        
        date: '',
        justification: '',
        value: '',
        value_juros: '',
        value_contraproposta: ''
    });

    const handleInputChange = useCallback((e:any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,            
            [name]: name === 'value' || name === 'value_juros' || name === 'value_contraproposta'  ? formatarValor(value).toString() : value
        }));
    }, []);

    
    useEffect(() => {
        
    }, [formData]);

    useEffect(() => {
        if(results && results.data) {
            dispatch(setSliceTasks(results.data));
        }
    },[results])
    
    
    const handlerSimulation = async () => {
        if(openDraweSimulation)
        {            
            try {

                await SchemaAgreement.validate(formData, { abortEarly: false });
                fetchSimulation(formData);
                openDraweSimulation('antecipation');
                setFormErrors({});    
            } catch (errors) {

                if (errors instanceof Yup.ValidationError) {
                    const validationErrors: { [key: string]: string } = {};
                    errors.inner.forEach((error) => {
                        validationErrors[error.path as string] = error.message
                    });
                    setFormErrors(validationErrors);
                }
            }
        }

    }
   
    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                            <div className="container-fluid">
                                <div className="d-block">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                            <form >
                                                
                                                <div className="separator d-flex flex-center">
                                                    <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Acordo</span>
                                                </div>
                                                <div className="pe-12 me-n12 mt-15" >
                                                    <div className="row gy-5">
                                                        
                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Data do acordo</label>
                                                            <div className='d-flex'>
                                                                <input 
                                                                    className="form-control"
                                                                    type='date'
                                                                    name="date"
                                                                    value={formData.date}
                                                                    onChange={(e) => {
                                                                            handleInputChange(e);
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.date && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.date}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Valor do contrato</label>
                                                            <div className='d-flex'>
                                                                <input 
                                                                    className="form-control" 
                                                                    name="value"
                                                                    value={formData.value}
                                                                    onChange={(e) => { handleInputChange(e)}
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.value && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.value}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Valor de contrato com juros</label>
                                                            <div className='d-flex'>
                                                            
                                                                <input 
                                                                    className="form-control" 
                                                                    name="value_juros"
                                                                    value={formData.value_juros}
                                                                    onChange={(e) => { handleInputChange(e)}
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.value_juros && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.value_juros}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Valor de Contraproposta</label>
                                                            <div className='d-flex'>
                                                            
                                                                <input 
                                                                    className="form-control" 
                                                                    name="value_contraproposta"
                                                                    value={formData.value_contraproposta}
                                                                    onChange={(e) => { handleInputChange(e)}
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.value_contraproposta && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.value_contraproposta}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-12 col-md-12  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Justificativa</label>
                                                            <div className='d-flex'>
                                                                <textarea 
                                                                    className="form-control" 
                                                                    name="justification"
                                                                    value={formData.justification}
                                                                    onChange={(e) => { handleInputChange(e)}
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.justification && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.justification}</div>
                                                            )}
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className="modal-footer border-0 mt-5">
                                                    <button type="button" className="btn btn-sm btn-light" onClick={closeModal}>Fechar</button>
                                                    {/* <button type="submit" className="btn btn-sm btn-success me-4">Enviar</button> */}
                                                    <button type="button" className="btn btn-sm btn-primary me-4" onClick={()=>handlerSimulation()}>Simular</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

