/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'

const DrawerFilter: FC = () => (
  <div
    id='kt_drawer_filter'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='filter'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_filter_toggle'
    data-kt-drawer-close='#kt_drawer_filter_close'
  >
    <div className='card w-100 rounded-0' id='kt_drawer_filter_modulo'>
      <div className='card-header pe-5' id='kt_drawer_filter_header'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            Opções de Filtros

          </div>
        </div>

        <div className='card-toolbar'>
          <div className='me-2'>
            {/* <button
              className='btn btn-sm btn-icon btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <i className='bi bi-three-dots fs-3'></i>
            </button> */}
          </div>

          <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_filter_close'>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </div>
        </div>
      </div>
      
      <div className="card-body" id="kt_drawer_filter_body">
            <div
                className=" me-n5 pe-5"
                data-kt-element="filter"
                data-kt-scroll="false"
                data-kt-scroll-activate="{default: false, lg: false}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_drawer_filter_header, #kt_drawer_filter_footer"
                data-kt-scroll-wrappers="#kt_drawer_filter_body"
                data-kt-scroll-offset="0px"
            >
                
            </div>
        </div>
      
    </div>
  </div>
)

export {DrawerFilter}
