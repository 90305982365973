import { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useLazyFetchGenerateBoletoQuery } from "../../../../app/redux/api/parcel/generateBoleto";

export const Invoice = () => {

    const simulation = useSelector((state:any) => state.sliceTasks);

    const colors = ['success', 'info', 'primary', 'danger'];

    const confirmGenerateBoleto = () => {
        Swal.fire({
            title: 'Atenção',
            text: "Deseja gerar boleto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Fechar',
            confirmButtonText: 'Sim, gerar!'
          }).then((result) => {
            if (result.isConfirmed) {
                generateBoleto();
            }
        })
    }

    const [fetchGenerateBoleto, results] = useLazyFetchGenerateBoletoQuery();

    const generateBoleto = () => {
        fetchGenerateBoleto(simulation.form);
    }

    useEffect(() => {
        if(results){
            // console.log('results boleto', results)
        }
    },[results])

    return (
        <div className="card-body p-lg-5">
            <div className="d-flex flex-column flex-xl-row">
                <div className="flex-lg-row-fluid me-xl-18">
                    <div className="mt-n1">
                        <div className="d-flex flex-stack pb-10">
                            <a href="#">
                                <img alt="Logo" style={{height:'54px'}} src={simulation?.logo || '/media/logos/hbr/cerebro-hbr.png'} />
                                
                            </a>
                        </div>

                        <div className="m-0">
                            <div className="fw-bold fs-3 text-gray-800 mb-8">Faturas {simulation?.invoice || '...'}</div>

                            <div className="row g-5 mb-7">
                                <div className="col-sm-6">
                                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Data de emissão:</div>

                                    <div className="fw-bold fs-6 text-gray-800">{simulation?.date_issue || '...'}</div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Data de vencimento:</div>

                                    <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                        <span className="">{simulation?.due_date || '...'}</span>

                                        <span className="fs-7 text-danger d-flex align-items-center"> <span className="bullet bullet-dot bg-danger me-2"></span>{simulation?.expires_in_days || '...'}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row g-5 mb-7">
                                <div className="col-sm-6">
                                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Emitir para:</div>

                                    <div className="fw-bold fs-6 text-gray-800">{simulation?.simulate_by || '...'}</div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Emitido por:</div>

                                    <div className="fw-bold fs-6 text-gray-800">{simulation?.issue_by || '...'}</div>
                                </div>
                            </div>

                            <div className="flex-grow-1">
                                <div className="table-responsive border-bottom mb-0">
                                    <table className="table">
                                        <thead>
                                            <tr className="border-bottom fs-6 fw-bold text-muted">
                                                <th className="min-w-175px pb-2">Descrição</th>
                                                <th className="min-w-70px text-end pb-2">Qnt. parcelas</th>

                                                <th className="min-w-100px text-end pb-2">Total parcelas</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                
                                                simulation && simulation.condition_payment && simulation.condition_payment.length > 0 ? (
                                                    simulation.condition_payment.map((row:any, index:number) => {

                                                       return  (
                                                                    <tr className="fw-bold text-gray-700 fs-5 text-end" key={index}>
                                                                        <td className="d-flex align-items-center pt-6">
                                                                            <i className={`fa fa-genderless mt-n5 text-${colors[index]} fs-2 me-2`}></i>
                                                                            

                                                                            <div className="col-sm-12">
                                                                                <div className="fw-bold fs-6 text-start text-gray-800">{ row.name_type_parcel || '...'}</div>

                                                                                <div className="fw-semibold fs-7 text-start text-gray-600">
                                                                                    { row.form_payment[0].toUpperCase() + row.form_payment.substring(1) || '...'}
                                                                                </div>
                                                                            </div>

                                                                        </td>

                                                                        <td className="pt-6">{row.number_parcel < 10 ? '0' + row.number_parcel : row.number_parcel || '...'}</td>

                                                                        <td className="pt-6 text-dark fs-7 fw-bolder">{row.value_format || '...'}</td>
                                                                    </tr>
                                                            )
                                                    })
                                                ):
                                                (
                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                        <td className="d-flex align-items-center pt-6">...</td>

                                                        <td className="pt-6">...</td>

                                                        <td className="pt-6 text-dark fw-bolder">...</td>
                                                    </tr>
                                                )
                                            }
                                            

                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="mw-300px">
                                        <div className="d-flex flex-stack mb-3">
                                            <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>

                                            <div className="text-end fw-bold fs-6 text-gray-800">{simulation?.subtotal || '...'}</div>
                                        </div>

                                        <div className="d-flex flex-stack mb-3">
                                            <div className="fw-semibold pe-10 text-gray-600 fs-7">Juros 0%</div>

                                            <div className="text-end fw-bold fs-6 text-gray-800">{simulation?.juros || '...'}</div>
                                        </div>

                                        <div className="d-flex flex-stack">
                                            <div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>

                                            <div className="text-end fw-bold fs-6 text-gray-800">{simulation?.total || '...'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="m-0">
                    <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                        <div className="mb-8">
                            <span className={`badge badge-light-${simulation?.is_approved === 'Aprovado' ? 'success' : 'danger'} me-2`}>{simulation?.is_approved || '...'}</span>
                        </div>

                        <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">DETALHES DO PAGAMENTO</h6>

                        <div className="mb-6">
                            <div className="fw-semibold text-gray-600 fs-7">Gerar Boleto</div>

                            <div className="fw-bold text-gray-800 fs-6">                                
                                
                                {
                                    simulation?.is_approved === 'Aprovado' 
                                    ? <button 
                                        type="button" 
                                        onClick={() => confirmGenerateBoleto()} 
                                        className="btn btn-sm btn-primary" 
                                        disabled={simulation?.is_approved !== 'Aprovado'}
                                        >Gerar agora</button>
                                    : '...'
                                
                                }
                                
                            </div>
                        </div>

                        <div className="mb-8">
                            <div className="fw-semibold text-gray-600 fs-7">Termo de pagamento:</div>

                            <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">
                                Ver termos<span className="fs-7 text-danger d-flex align-items-center"> <span className="bullet bullet-dot bg-danger mx-2"></span>{simulation?.expires_in_days || '...'}</span>
                            </div>
                        </div>

                        <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">VISÃO GERAL</h6>

                        <div className="mb-6">
                            <div className="fw-semibold text-gray-600 fs-7">Contrato</div>

                            <div className="fw-bold fs-6 text-gray-800">{simulation?.name_contract || '...'}</div>
                        </div>

                        <div className="mb-6">
                            <div className="fw-semibold text-gray-600 fs-7">Simulado por:</div>

                            <div className="fw-bold text-gray-800 fs-6">{simulation?.issue_for || '...'}</div>
                        </div>

                        <div className="m-0">
                            <div className="fw-semibold text-gray-600 fs-7">Data da Simulação:</div>

                            <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">{simulation?.date_simulate || '...'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
