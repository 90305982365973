import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../auth/getToken';

const API_URL = process.env.REACT_APP_API_URL;

export const tableParcels = createApi({
    reducerPath: 'tableParcels',
    baseQuery:fetchBaseQuery({
        baseUrl:API_URL,
        prepareHeaders:(headers) => {
            const  dataLocalStorage = getToken();

            if (!headers.has("Authorization"))
            {
                if (dataLocalStorage?.token) {
                    headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
                }
            }
            
            return headers;
        },
    }),
    endpoints:(builder) => ({
        fetchParcels: builder.query({
            query:(params) => ({
                url:`/module/tableParcels`,
                method: 'POST',
                body:{token:getToken()?.token},
            }),
        }),
    }),
});

export const {useFetchParcelsQuery, useLazyFetchParcelsQuery} = tableParcels;