import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { billingPosition } from './api/payment/billingPosition';
import { getClient } from './api/client/getClient';
import { getContract } from './api/contract/getContract';
import { simulation } from './api/parcel/simulation';
// import { paidContract } from './api/contract/paidContract';
// import { cancellationContract } from './api/contract/cancellationContract';
// import { agreementContract } from './api/contract/agreementContract';
import { listContracts } from './contracts/listContracts';
import SliceTasksReducer from './slices/actionSliceTasks';
import filterReducer from './filter/filterSlice';
import resetReducer  from './filter/resetSlice';
import { generateBoleto } from './api/parcel/generateBoleto';
import { tableParcels } from './api/contract/tableParcels';

const rootReducer = combineReducers({
    filter: filterReducer,
    reset: resetReducer,
    sliceTasks: SliceTasksReducer,
    // [listContracts.reducerPath] : listContracts.reducer,
    [billingPosition.reducerPath]: billingPosition.reducer,
    [getContract.reducerPath]: getContract.reducer,
    [getClient.reducerPath]: getClient.reducer,
    [simulation.reducerPath]: simulation.reducer,
    // [paidContract.reducerPath]: paidContract.reducer,
    // [cancellationContract.reducerPath]: cancellationContract.reducer,
    // [agreementContract.reducerPath]: agreementContract.reducer,
    [tableParcels.reducerPath]: tableParcels.reducer,
    [generateBoleto.reducerPath]: generateBoleto.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    // .concat(listContracts.middleware)
    .concat(billingPosition.middleware)
    .concat(getContract.middleware)
    .concat(getClient.middleware)
    // .concat(paidContract.middleware)
    .concat(simulation.middleware)
    // .concat(cancellationContract.middleware)
    // .concat(agreementContract.middleware)
    .concat(generateBoleto.middleware)
    .concat(tableParcels.middleware)
});


setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
  
export default store;