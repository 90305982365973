import { Invoice } from "../invoices/Invoice";
import { KTSVG, toAbsoluteUrl } from "../../../helpers"
import { useEffect, useState } from "react";

export const DrawerSimulation = ({ isOpenDrawer, closeDraweSimulation }: { isOpenDrawer: boolean; closeDraweSimulation: (action: boolean) => void }) => {
    
    const closeDrawer = () => {
        closeDraweSimulation(false);
    }


    return (
        <div
            id="kt_activities"
            className={`bg-body drawer drawer-start ${
                isOpenDrawer ? "drawer-on" : ""
              }`}
            data-kt-drawer="true"
            data-kt-drawer-name="activities"
            data-kt-drawer-activate="true"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'893px', 'lg': '893px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_activities_toggle"
            data-kt-drawer-close="#kt_activities_close"
            style={{width:'893px!important', zIndex:1058}}
        >
            <div className="card shadow-none border-0 rounded-0"  style={{width:'inherit!important'}}>
                <div className="card-header" id="kt_activities_header" style={{minHeight:'50px'}}>
                    <h3 className="card-title fw-bold text-dark">Simulação</h3>

                    <div className="card-toolbar">
                        <button type="button" onClick={() => closeDrawer()} className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                        </button>
                    </div>
                </div>

                <div className="card-body position-relative" style={{widows:'1040px'}} id="kt_activities_body">
                    <div
                        id="kt_activities_scroll"
                        className="position-relative scroll-y me-n5 pe-5"
                        style={{height:'521px'}}
                    >

                        <Invoice/>

                    </div>
                </div>
            </div>
        </div>

    );
}