import { converteFormatoData } from "./dates/date";

export const formatMessageWhatsapp = (message:string) => {
    return encodeURIComponent(message);
}

export const messageWhatsapp = (data:any) => {

    const due_date = converteFormatoData(data.due_date);
    var now = new Date();
    var dataComparacao = new Date(due_date);

    let status = 'Pendente';

    if (dataComparacao > now) {
        status = `Pendente - à vencer em ${data.due_date}`;
    } else if (dataComparacao < now) {
        status = `Pendente - venceu em ${data.due_date}`;
    } else {
        status = `Pendente - vencendo hoje`;
    }

    const text = `Olá, preciso regularizar minha parcela, segue as informações.
        Contrato: ${data.contract_name},
        Origem: 'Portal do cliente',
        Forma de pagamento: ${data.form_payment},
        Vencimento: ${data.due_date},
        Valor: R$ ${data.value},
        Status: ${status}
                `;

    return text;

}