import { KTSVG, toAbsoluteUrl } from "../../helpers"
import { ChartDonutOneLine } from "../../helpers/components/chart/ChartDonutOneLine"
import { ProgressOneLine } from "../../helpers/components/chart/ProgressOneline"

import { useSelector } from 'react-redux';
import { useFetchBillingPositionQuery } from "../../../app/redux/api/payment/billingPosition";
import { Link } from "react-router-dom";
import { FormAntecipation } from "./modal/FormAntecipation";
import { useEffect, useState } from "react";
import { FormPaid } from "./modal/FormPaid";
import { FormCancellation } from "./modal/FormCancellation";
import { FormAgreement } from "./modal/FormAgreement";
import { useFetchContractQuery } from "../../../app/redux/api/contract/getContract";
import { useAuth } from "../../../app/modules/auth";
// import { useFetchParcelAnticipationQuery } from "../../../app/redux/api/parcel/parcelAnticipation";
// import { RootStateFilter } from "../../../app/interface/I_FormSlice";
import { DrawerSimulation } from "../../layout/components/drawer/DrawerSimulation";
import { TableOptions } from "../../../app/interface/I_Table";
import TableAdvanced from "../../layout/components/tables/TableAdvanced";
import HeaderTableParcel from "./HeaderTableParcel";
import { IFilterParcel, IParcels } from "../../../app/interface/I_Parcel";


import { useDispatch } from 'react-redux';
import { setFilter } from "../../../app/redux/filter/filterSlice";

import DateRangePickerComponent from "../../layout/components/dates/DateRangePickerComponent";
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment-timezone";
import "moment/locale/pt-br";
import { optionsTypePayment } from "../../../app/globals/OptionsGlobals";
import { Option } from "../../../app/interface/I_General";

export const IncomeStatement = () => {

    const { auth, logout, setCurrentUser } = useAuth();

    const dispatch = useDispatch();

    const filterForm = useSelector((state: any) => state.filter);

    const [formData, setFormData] = useState({
        id_contract: '',
        start_date: '',
        end_date: '',
        type_payment: '',
        form_payment: ''
    });

    useEffect(() => {

        setFormData(prevState => ({
            ...prevState,
            id_contract: filterForm && filterForm.id_contract !== '' && filterForm.id_contract !== null ? filterForm.id_contract : '',
            name_contract: filterForm && filterForm.name !== '' && filterForm.name !== null ? filterForm.name : '',
            name_unit: filterForm && filterForm.name_unit !== '' && filterForm.name_unit !== null ? filterForm.name_unit : '',
            client: filterForm && filterForm.id_client !== '' && filterForm.id_client !== null ? filterForm.id_client : '',
        }))

    }, [filterForm]);

    const [resetDueDate, setResetDueDate] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
    });

    function handleDateRangeDueDate(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData(prevState => ({
            ...prevState,
            start_date: start_date.format('YYYY-MM-DD'),
            end_date: end_date.format('YYYY-MM-DD')
        }));
    }

    const handleResetComplete = () => {
        setResetDueDate(false);
    };

    const resetFilterForm = () => {

        setResetDueDate(true);

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({
            start_date: '',
            end_date: '',
            type_payment: '',
            form_payment: ''
        }));

        setFormData(prevState => ({
            ...prevState,
            start_date: '',
            end_date: '',
            type_payment: '',
            form_payment: ''
        }));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));

    }

    const [tableData, setTableData] = useState({
        id: "0",
        number_parcel: "",
        id_contract: "",
        type_parcel: "",
        link: "#",
        form_payment: "",
        due_date: "",
        value: "",
        status: "",
    });


    const ComponentTasks = [
        {
            id: '47aad2c3a8a',
            title: 'Antecipação',
            description: 'Antecipe suas parcelas',
            icon: 'media/icons/duotune/general/gen014.svg',
            background: '/media/outros/abstract-1.svg',
            // icon:'/media/svg/files/upload.svg',
            routes: '/antecipation',
            action: 'openModalAntecipation'
        },
        {
            id: 'a50c1efbf417',
            title: 'Quitação',
            description: 'Quite seu contrato',
            icon: '/media/icons/duotune/general/gen020.svg',
            background: '/media/outros/abstract-2.svg',
            routes: '/paid',
            action: 'openModalPaid'
        },
        {
            id: '0f5ba114f8fd',
            title: 'Distrato',
            description: 'Distrate seu contrato',
            icon: '/media/icons/duotune/general/gen050.svg',
            background: '/media/outros/abstract-4.svg',
            routes: '/cancellation',
            action: 'openModalCancellation'
        },
        {
            id: '485njhserip90',
            title: 'Acordo',
            description: 'Faça um acordo',
            icon: '/media/icons/duotune/general/gen049.svg',
            background: '/media/outros/abstract-4.svg',
            routes: '/agreement',
            action: 'openModalAgreement'
        },
    ]


    useEffect(() => {

        dispatch(setFilter(formData));

    }, [formData.id_contract])

    const handleSelectChange = <K extends keyof IFilterParcel>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";

        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value,
        }));

    };

    // tras dados de posicao de cobrança
    const { data: billingPosition, error, isLoading } = useFetchBillingPositionQuery(formData);

    useEffect(() => {

    }, [billingPosition])

    console.log('filterForm ', filterForm)
    console.log('formData ', formData)
    console.log('billingPosition ', billingPosition)

    const optionsIncomeStatement: TableOptions = {
        // id:formValue.id_contract,
        header: HeaderTableParcel('parcels'),
        iInterface: 'parcels',
        endPoint: `tableParcels`,
        methodEndPoint: 'post',
        title: 'Lista de Parcelas',
        classHeadTd: 'w-auto text-center',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: 'text-center',
        classTbodyTd: 'w-auto text-center',
        isHidePaginate: true,
        isHideChekbox: true,
        // funDeleteInMass: 'deleteParcelasInMassa',
        // funChangeInMass: 'changeContractInMassa',
        // textDelete: 'Parcelas',
    };

    const optionsTypePayments: Option[] = [
        { value: "", label: "Todos" },
        { value: "LQ", label: "Liquidados" },
        { value: "VC", label: "Vencidos" },
        { value: "RC", label: "Pendentes" }
    ];


    const [isOpenModalAntecipation, setIsOpenModalAntecipation] = useState(false);
    const [isOpenModalPaid, setIsOpenModalPaid] = useState(false);
    const [isOpenModalCancellation, setIsOpenModalCancellation] = useState(false);
    const [isOpenModalAgreement, setIsOpenModalAgreement] = useState(false);


    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const handleClearfilter = (type: string) => {

        setResetDueDate(true);

        setFormData((prevState) => ({
            ...prevState,
            start_date: '',
            end_date: ''
        }));

        dispatch(setFilter(formData));
    }
    // OPEN MODALS
    /** abre modal de antecipação */
    const openModalAntecipation = () => {

        setIsOpenModalAntecipation(true);
    };

    /** abre modal de quitacao */
    const openModalPaid = () => {

        setIsOpenModalPaid(true);
    };

    /** abre modal de distrato */
    const openModalCancellation = () => {

        setIsOpenModalCancellation(true);
    };

    /** abre modal de acordo */
    const openModalAgreement = () => {

        setIsOpenModalAgreement(true);
    };


    // CLOSE MODALS

    /** fecha modal de antecipação */
    const closeFormAntecipation = () => {
        setIsOpenModalAntecipation(false);
    };

    /** fecha modal de quitacao */
    const closeFormPaid = () => {
        setIsOpenModalPaid(false);
    };

    /** fecha modal de distrato */
    const closeFormCancellation = () => {
        setIsOpenModalCancellation(false);
    };

    /** fecha modal de distrato */
    const closeFormAgreement = () => {
        setIsOpenModalAgreement(false);
    };

    const handleActionClick = (action: any) => {
        // Mapeamento de ações para funções correspondentes
        const actionFunctions: any = {
            openModalAntecipation,
            openModalPaid,
            openModalCancellation,
            openModalAgreement,
        };

        // Verifica se a ação existe no mapeamento e chama a função correspondente
        if (actionFunctions[action]) {
            actionFunctions[action]();
        }
    };


    // ações do drawer
    const openDraweSimulation = (action: string) => {
        setIsOpenDrawer(true);

    };

    const closeDraweSimulation = (action: boolean) => {
        setIsOpenDrawer(action);

    };

    return (
        <>

            <form className="rounded p-7 bg-white mb-5" id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start mb-n10">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        {/*  */}

                        <div className="col-md-4">
                            <label className='form-label fw-bold'>Situação</label>
                            <div className=''>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 12 }) }}
                                    name="type_pyment"
                                    className="w-auto"
                                    placeholder="Selecione um tipo"
                                    value={{ value: formData.type_payment, label: optionsTypePayments.find(option => option.value === formData.type_payment)?.label }}
                                    options={optionsTypePayments}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_payment")}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label className='form-label fw-bold'>Forma de Pagamento</label>
                            <div className='d-flex'>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Selecione"
                                    value={formData.form_payment !== '0' && formData.form_payment !== '' ? { value: formData.form_payment, label: optionsTypePayment.find(option => option.value === formData.form_payment)?.label } : null}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "form_payment")}
                                    options={optionsTypePayment}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label className='form-label fw-bold'>Vencimento</label>
                            <div className="input-group">
                                <DateRangePickerComponent
                                    onDateRangeSelection={handleDateRangeDueDate}
                                    className=''
                                    resetDate={resetDueDate}
                                    onResetComplete={handleResetComplete}
                                />
                                <button className="btn btn-sm btn-light btn-active-light-primary" onClick={() => handleClearfilter('due_date')}><KTSVG path="/media/icons/duotune/art/art004.svg" />Período total</button>
                            </div>
                            {/* Rest of your component code */}
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type="submit" data-kt-indicator='off' className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                                <span className="indicator-label">
                                    Filtrar
                                    <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg" />
                                </span>
                                <span className="indicator-progress">
                                    Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>

                        {/*  */}
                    </div>
                </div>
            </form>

            <div className="card mb-6 mb-xl-9">

                <div className="card card-flush py-4">
                    <div className="card-header">
                        <div className="card-title">
                            <h2>Contratos</h2>
                        </div>
                    </div>
                </div>

                <div className="card-header align-items-center py-5 gap-2 gap-md-5" style={{ display: 'none' }}>
                    <div className="d-flex align-items-center flex-wrap gap-2">

                        {
                            // ComponentTasks.map((row, index) => {

                            //     return (

                            //         <div className="ms-3 image-input image-input-outline" key={row.id || index}>

                            //             <a onClick={() => handleActionClick(row.action) } className="d-flex flex-column flex-center image-input-wrapper text-center text-gray-800 text-hover-primary bg-hover-light rounded py-4 px-3 mb-3">
                            //                 <KTSVG className="svg-icon svg-icon-2x" path={row.icon}/>
                            //                 <span className="fw-semibold">{row.title}</span>
                            //             </a>
                            //             {/* </Link> */}
                            //         </div>
                            //     )
                            // })
                        }

                    </div>
                </div>
                <div className="card-body pt-9 pb-0">
                    {/* <div className="d-flex flex-wrap flex-sm-nowrap mb-6"> */}
                    <div className="">
                        <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                            # Acompanhe o progresso de todas as cobranças
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="d-flex col-12">

                                <div className="col-md-4 col-lg-4 col-sm-12 d-flex align-items-center bg-light-success rounded p-5 mb-7">


                                    <div className="flex-grow-1 me-2">
                                        {/* <KTSVG path={"/media/icons/duotune/general/gen048.svg"} className="svg-icon-2hx" /> */}
                                        <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6"> Pagos</a>
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">{billingPosition?.data?.paid?.valueMask || 'R$ 0,00'}</a>

                                        </div>
                                    </div>

                                    {/* Liquidados */}
                                    <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'success'} idElement={"chart_pay_out"} quantity={parseInt(billingPosition?.data?.paid?.count) || 0} valuePercent={parseInt(billingPosition?.data?.paid?.percentMask) || 0} />


                                </div>

                                <div className="col-md-4 col-lg-4 col-sm-12 ms-1 d-flex align-items-center bg-light-danger rounded p-5 mb-7">

                                    <div className="flex-grow-1 me-2">
                                        <a href="#" className="fw-bold text-gray-800 text-hover-danger fs-6">Vencidos</a>
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <a href="#" className="fs-5 text-gray-800 text-hover-danger fw-bold">{billingPosition?.data?.overdue?.valueMask || 'R$ 0,00'}</a>

                                        </div>
                                    </div>

                                    <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'danger'} idElement={"chart_overdue"} quantity={parseInt(billingPosition?.data?.overdue?.count) || 0} valuePercent={parseInt(billingPosition?.data?.overdue?.percentMask) || 0} />
                                </div>


                                <div className="col-md-4 col-lg-4 col-sm-12 ms-1 d-flex align-items-center bg-light-warning rounded p-5 mb-7">
                                    <i className="ki-duotone ki-abstract-26 text-success fs-1 me-5"><span className="path1"></span><span className="path2"></span></i>

                                    <div className="flex-grow-1 me-2">
                                        <a href="#" className="fw-bold text-gray-800 text-hover-warning fs-6">A Pagar</a>
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <a href="#" className="fs-5 text-gray-800 text-hover-warning fw-bold">{billingPosition?.data?.receive?.valueMask || 'R$ 0,00'}</a>
                                            {/* <div className="text-gray-400 fw-semibold fs-7">Quantidade: 13</div> */}
                                        </div>
                                    </div>

                                    <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'warning'} idElement={"chart_pay"} quantity={parseInt(billingPosition?.data?.receive?.count) || 0} valuePercent={parseInt(billingPosition?.data?.receive?.percentMask) || 0} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* </div> */}

                    <TableAdvanced
                        options={optionsIncomeStatement}
                        // optionsActionInMassa={optionsActionInMassa}
                        tableData={tableData}
                    // handleInsert={handleInsert}
                    />
                </div>
                {
                    isOpenModalAntecipation &&
                    <FormAntecipation
                        formValues={formData}
                        idContract={formData.id_contract}
                        closeModal={closeFormAntecipation}
                        openDraweSimulation={openDraweSimulation}
                    />
                }

                {
                    isOpenModalPaid &&
                    <FormPaid
                        formValues={formData}
                        idContract={formData.id_contract}
                        closeModal={closeFormPaid}
                        openDraweSimulation={openDraweSimulation}
                    />
                }

                {
                    isOpenModalCancellation &&
                    <FormCancellation
                        formValues={formData}
                        idContract={formData.id_contract}
                        closeModal={closeFormCancellation}
                        openDraweSimulation={openDraweSimulation}
                    />
                }

                {
                    isOpenModalAgreement &&
                    <FormAgreement

                        formValues={formData}
                        idContract={formData.id_contract}
                        closeModal={closeFormAgreement}
                        openDraweSimulation={openDraweSimulation}
                    />
                }

                <DrawerSimulation
                    isOpenDrawer={isOpenDrawer}
                    closeDraweSimulation={closeDraweSimulation}

                />
            </div>
        </>
    )
}