import React, { useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../helpers"; 
import Select from "react-select";
import { useAuth } from "../../../../app/modules/auth";
import * as Yup from 'yup'
import { SchemaAntecipation } from "../schemas/SchemaAntecipation";
import { useMemo } from 'react';
import {  formatarValor } from "../../../helpers/functions/numbers";
import Swal from "sweetalert2";
import { FormPropsContract } from "../../../../app/interface/I_Contract";
import { useLazyFetchSimulationQuery } from "../../../../app/redux/api/parcel/simulation";
import { setSliceTasks } from "../../../../app/redux/slices/actionSliceTasks";
import { useDispatch } from "react-redux";

export const FormAntecipation: React.FC<FormPropsContract> = ({ 
    formValues,
    idContract,
    closeModal,
    openDraweSimulation,
    onFormSubmit
}) => {
    
    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        type_antecipation: string;
        include_intermediate: string;
    }

    // tipo de antecipação
    const optionsTypeAntecipation: Option[] = [
        { value: "valor", label: "Valor" },
        { value: "meses", label: "Meses" }
    ];

    // se inclui intermediarias
    const optionsIncludeIntermediate: Option[] = [
        { value: "nao", label: "Não" },
        { value: "sim", label: "Sim" }
    ];

    const {auth, currentUser} = useAuth();

    const dispatch = useDispatch();
    
    const [formData, setFormData] = useState({
        id_contract:idContract,
        name_client: currentUser?.name || '',
        client: formValues?.client || '',
        name_contract: formValues?.name_contract || '',
        name_unit: formValues?.name_unit || '',
        date:'',
        number_parcel:'',
        type_antecipation: '',
        include_intermediate:'',
        value: '',
        action:'anticipation_parcel'
    });    
    
    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({        
        
        date:'',
        number_parcel:'',
        type_antecipation: '',
        include_intermediate: '',
        value: ''
    });
   
    const [fetchSimulation, results] = useLazyFetchSimulationQuery()
    
    const handleInputChange = useCallback((e:any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,            
            [name]: name === 'value' ? formatarValor(value).toString() : value
        }));
    }, []);

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        setFormData(prevState => ({
            ...prevState,                
            [fieldName]: value.toString(),
        }));
    };

    useEffect(() => {
        
    }, [formData]);

    useEffect(() => {
        if(results && results.data) {
            dispatch(setSliceTasks(results.data));
        }
    },[results])

    const handlerSimulation = async () => {
        if(openDraweSimulation)
        {            
            try {

                await SchemaAntecipation.validate(formData, { abortEarly: false });
                fetchSimulation(formData);
                openDraweSimulation('antecipation');
                setFormErrors({});    
            } catch (errors) {

                if (errors instanceof Yup.ValidationError) {
                    const validationErrors: { [key: string]: string } = {};
                    errors.inner.forEach((error) => {
                        validationErrors[error.path as string] = error.message
                    });
                    setFormErrors(validationErrors);
                }
            }
        }

    }

    // envia formulario
    /*
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
               
        try {

            await SchemaAntecipation.validate(formData, { abortEarly: false });
            setFormErrors({});    
            
            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja enviar antecipação de parcela?",
                showCancelButton: true,
                confirmButtonText: 'Sim, enviar!',
                cancelButtonText: 'Não',
            }).then((result) => {
                
                if (result.isConfirmed)
                {    
                    // saveForm();                    
                }
            });
            

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }        
    };

    const saveForm =  async() => {

        if( auth && auth.api_token)
        {
            // const response = await insertContractAPI(formData, auth);
            const response = {data:{success:{}, data:{}}}
            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Enviado com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

                if(response.data.data !== "0" && response.data.data !== null && response.data.data !== "" )
                {
                    let lastId = response.data.data.toString();  

                    setFormData(prevState => ({
                        ...prevState,                
                        ['id']: lastId
                    }));
                    
                }

            }else{
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }
    */
   
    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                            <div className="container-fluid">
                                <div className="d-block">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                            <form >
                                                
                                                <div className="separator d-flex flex-center">
                                                    <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Antecipação de parcelas</span>
                                                </div>
                                                <div className="pe-12 me-n12 mt-15" >
                                                    <div className="row gy-5">
                                                        
                                                        <div className="col-lg-2 col-md-2  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Data</label>
                                                            <div className='d-flex'>
                                                                <input 
                                                                    className="form-control" 
                                                                    type="date"
                                                                    name="date"
                                                                    value={formData.date}
                                                                    onChange={(e) => {
                                                                            handleInputChange(e);
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                            {formErrors.date && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.date}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-2 col-md-2  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Parcela</label>
                                                            <div className='d-flex'>
                                                                <input 
                                                                    className="form-control" 
                                                                    name="number_parcel"
                                                                    pattern="[1-9][0-9]*"
                                                                    value={formData.number_parcel}
                                                                    onChange={(e) => {
                                                                            handleInputChange(e);
                                                                        }
                                                                    }
                                                                />
                                                            </div>

                                                            {formErrors.number_parcel && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.number_parcel}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-2 col-md-2  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Incluir Intermediaria?</label>
                                                            <div className='d-flex'>
                                                                <Select
                                                                    className="w-450px"
                                                                    placeholder="Selecione"
                                                                    name="include_intermediate"
                                                                    value={formData.include_intermediate !== '' ? { value: formData.include_intermediate, label: optionsIncludeIntermediate.find(option => option.value === formData.include_intermediate)?.label } : null}

                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "include_intermediate")}
                                                                    options={optionsIncludeIntermediate}
                                                                />
                                                            </div>
                                                            {formErrors.include_intermediate && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.include_intermediate}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Tipo de Antecipação</label>
                                                            <div className='d-flex'>
                                                                <Select
                                                                    className="w-450px"
                                                                    placeholder="Selecione"
                                                                    name="type_antecipation"
                                                                    value={formData.type_antecipation !== '' ? { value: formData.type_antecipation, label: optionsTypeAntecipation.find(option => option.value === formData.type_antecipation)?.label } : null}

                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_antecipation")}
                                                                    options={optionsTypeAntecipation}
                                                                />
                                                            </div>
                                                            {formErrors.type_antecipation && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.type_antecipation}</div>
                                                            )}
                                                        </div>

                                                        <div className="col-lg-3 col-md-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Valor</label>
                                                            <div className='d-flex'>
                                                                <input 
                                                                    className="form-control" 
                                                                    name="value"
                                                                    value={formData.value}
                                                                    onChange={(e) => { handleInputChange(e)}}
                                                                />
                                                            </div>
                                                            
                                                            {formErrors.value && (
                                                                <div className="fv-plugins-message-container invalid-feedback">{formErrors.value}</div>
                                                            )}
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className="modal-footer border-0 mt-5">
                                                    <button type="button" className="btn btn-sm btn-light" onClick={closeModal}>Fechar</button>
                                                    {/* <button type="submit" className="btn btn-sm btn-success me-4">Enviar</button> */}
                                                    <button type="button" className="btn btn-sm btn-primary me-4" onClick={()=>handlerSimulation()}>Simular</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}