import * as Yup from 'yup'

export const SchemaAntecipation = Yup.object().shape({
    // contract: Yup.object().shape({
        number_parcel: Yup.string().test('is-zero-value', 'Adicionar parcelas', (value) => {
            if (value === '0' || value === '') {
              return false;
            }
            return true;
        }),
        date: Yup.string().required('Adicionar data'),
        include_intermediate: Yup.string().required('Existe intermediaria?'),
        type_antecipation: Yup.string().required('Adicionar tipo de Anetcipação'),
        value: Yup.string().test('is-zero-value', 'O valor deve ser maior que zero', (value) => {
            if (value === 'R$ ' || value === 'R$ 0' || value === 'R$ 0.0' || value === 'R$ 0.00 ' || value === ''  || value === ' ' || value === 'R$ 0,00') {
              return false;
            }
            return true;
        })
        // description: Yup.string().required('É necessário adicionar uma descrição'),
    // })
});
