import * as Yup from 'yup'

export const SchemaPaid = Yup.object().shape({
   
    date: Yup.string().required('Adicionar data da quitação'),
    form_payment: Yup.string().required('Adicionar forma de quitação'),
    value: Yup.string().test('is-zero-value', 'O valor deve ser maior que zero', (value) => {
        if (value === 'R$ ' || value === 'R$ 0' || value === 'R$ 0.0' || value === 'R$ 0.00 ' || value === ''  || value === ' ' || value === 'R$ 0,00') {
            return false;
        }
        return true;
    }),
    justification: Yup.string().required('É necessário adicionar uma justificativa'),

});
