export const isDateExpired =(dateString: string): boolean => {
    const currentDate = new Date();
    const [day, month, year] = dateString.split('/').map(Number);
    
    // Lembrando que os meses em JavaScript vão de 0 a 11, então subtraímos 1 do mês.
    const providedDate = new Date(year, month - 1, day);
  
    return providedDate < currentDate;
}


export const converteFormatoData = (dataString:string) => {
    var partes = dataString.split('/');
    return partes[2] + '-' + partes[1] + '-' + partes[0];
}