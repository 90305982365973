import { optionsStatusParcel } from "../../../app/globals/OptionsGlobals";
import { IHeaderWithCell, IHeader } from "../../../app/interface";
import { useAuth } from "../../../app/modules/auth";
import { KTSVG } from "../../helpers";
import { downloadBoletoPdf, downloadBoletos } from "../../helpers/functions/boleto/boleto";
import { isDateExpired } from "../../helpers/functions/dates/date";
import { formatMessageWhatsapp } from "../../helpers/functions/whatsapp";
import { messageWhatsapp } from "../../helpers/functions/whatsapp";

export const HeaderTableParcel = (type: string, handleEdit?: (row: any) => void): IHeaderWithCell[] => {

    const { auth } = useAuth();

    switch (type) {

        case 'parcels': // parcelas
            return [

                {
                    Header: 'Parcela', accessor: (row: any) => { return (`${row.number_parcel} º`) }
                },
                // { Header: 'Tipo', accessor: 'type_parcel' },
                { Header: 'Forma', accessor: 'form_payment' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Valor', accessor: 'value' },
                {
                    Header: 'Situação',
                    accessor: function (row: any) {
                        if (row.deleted === null) {
                            if (row.mark_parcel_paid !== '' && row.mark_parcel_paid !== null) {
                                return row.mark_parcel_paid;

                            } else {

                                const statusParcel = optionsStatusParcel.find(item => item.value === row.status)?.writeLabel || 'Pendente';
                                return statusParcel
                            }
                        } else {
                            return (<span className="badge badge-light-danger fs-base">Deletado</span>)
                        }
                    }
                },
                {
                    Header: 'Ações',
                    accessor: (row: any) => {
                        let classBgExpirate = 'btn-light';
                        let classIcon = 'svg-icon-1x';

                        const textMessage = messageWhatsapp(row)
                        const messageEncoded = formatMessageWhatsapp(textMessage)

                        var linkWhatsApp = `https://wa.me/5511997561162?text=${messageEncoded}`;

                        if (row?.hasOwnProperty('due_date') && !!row?.due_date) {
                            if (row?.hasOwnProperty('status')) {
                                if (row?.status !== '1' && row?.status !== '2') {
                                    classBgExpirate = isDateExpired(row?.due_date) ? 'text-danger' : 'btn-light';
                                    classIcon = 'svg-icon svg-icon-1x'
                                }
                            }
                        }

                        if (row.deleted === null) {
                            // const fileNameBoleto = !!row.token_boleto ? `Boleto ${row.our_number}, Vencimento ${row.due_date}, ${row.payer}` : '';
                            return (row.status === '1' && row.status === '2') && (row.confirmation_send === 'sim')
                                ? (
                                    <div className="row" id={`actions_${row.id}`}>
                                        <KTSVG className={`svg-icon svg-icon-2x me-2 mb-1 svg-icon-success me-3`} path="/media/icons/duotune/general/gen047.svg" />
                                    </div>
                                ) : (
                                    <div className="row" id={`actions_${row.id}`}>
                                        {/* EXIBIÇÂO DO BOLETO */}
                                        {
                                            (() => {

                                                if (row.name_integration === 'api_cloud' && !!row.token_boleto && !!row.link_boleto && !!row.token_remessa && row.confirmation_send === 'sim') {
                                                    return (

                                                        <button
                                                            id={`boleto_${row.id}`}
                                                            className="btn btn-sm w-20px fw-bold btn-light btn-active-color-danger"
                                                            onClick={() => downloadBoletos(row.link_boleto)}
                                                        >
                                                            <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/files/fil021.svg"} />
                                                        </button>
                                                    );
                                                } else if (row.name_integration === 'itau_bank' && !!row.token_boleto && !!row.link_boleto) {

                                                    return (

                                                        <button
                                                            id={`boleto_${row.id}`}
                                                            className="btn btn-sm w-20px fw-bold btn-light btn-active-color-danger"
                                                            onClick={() => downloadBoletos(row.link_boleto)}
                                                        >
                                                            <KTSVG className={`${classIcon} ms-n2`} path={"/media/icons/duotune/files/fil021.svg"} />
                                                        </button>
                                                    );
                                                } else if (row.status == 0) {
                                                    return (
                                                        <div className="ps-0">
                                                            <a href={linkWhatsApp} target="_blank" className={`${classBgExpirate} fw-bold text-hover-primary mb-1 fs-6 text-start pe-0`}>
                                                                <KTSVG className={`svg-icon svg-icon-2x me-2 ${classBgExpirate}`} path="/media/icons/duotune/technology/teh002.svg" />
                                                                Falar com financeiro
                                                            </a>
                                                            {/* <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0"></span> */}
                                                        </div>
                                                    );
                                                } else {
                                                    return <>Pago</>;
                                                }
                                            })()
                                        }
                                    </div>
                                )

                        } else {
                            return (<KTSVG className={`svg-icon svg-icon-2x svg-icon-success me-3`} path="/media/icons/duotune/general/gen047.svg" />)
                            // return (<KTSVG className="svg-icon svg-icon-2x svg-icon-danger ms-n5 " path="/media/icons/duotune/ecommerce/ecm010.svg"/>)
                        }
                    }
                }
            ];
        default:
            return [

                { Header: 'Parcela', accessor: 'number_parcel' },
                // { Header: 'Tipo', accessor: 'type_parcel' },
                { Header: 'Forma', accessor: 'form_payment' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Situação', accessor: 'status' }
            ];
    }
};

export default HeaderTableParcel