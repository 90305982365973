/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            
            <SidebarMenuItem 
                to='/tasks' 
                title='Tarefas'
                icon='/media/icons/duotune/finance/fin002.svg'
            />
            <SidebarMenuItem 
                to='/antecipation' 
                title='Antecipacao'
                icon='/media/icons/duotune/finance/fin002.svg'
            />

        </>
    )
}

export {SidebarMenuMain}
