import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IClient } from '../../../interface/I_Client';
import { getToken } from '../auth/getToken';

const API_URL = process.env.REACT_APP_API_URL;

export const getClient = createApi({
    reducerPath: 'getClient',
    baseQuery: fetchBaseQuery({ 
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            const  dataLocalStorage = getToken();
            if (dataLocalStorage?.token) {
                headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchClient: builder.query({
            query: (params) => {
                return {
                  url: `/module/getClient`,
                  method: 'POST',
                  body: JSON.stringify(params),
                };
            },
            
        }),
    }),
});


export const { useFetchClientQuery } = getClient