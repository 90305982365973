import { statusContract } from "../Constants";
import { Option } from "../interface/I_General";

// situação
export const optionsStatusParcel: Option[] = [
    { value: "0", field:'status', label: "Pendente", writeLabel:'Pendente' },
    { value: "1", field:'status', label: "Pago e compensado", writeLabel:'Pago e compensado (manual)' },
    { value: "2", field:'status', label: "Pago sem compensação", writeLabel:'Pago sem compensação (manual)' }
];

// forma de pagamento
export const optionsTypePayment: Option[] = [
    { value: "", field:'form_payment', label: "Todos" },
    { value: "Boleto", field:'form_payment', label: "Boleto" },
    { value: "Cartao", field:'form_payment', label: "Cartão" },
    { value: "Cheque", field:'form_payment', label: "Cheque" },
    { value: "Dinheiro", field:'form_payment', label: "Dinheiro" },
    { value: "Permuta", field:'form_payment', label: "Permuta" },
    { value: "Pix", field:'form_payment', label: "Pix" },
    { value: "Transferencia", field:'form_payment', label: "Transferência" }
];

// se a cao na integracao é emitir bioletos ou consultar
export const optionsTypeApiBoletos: Option[] = [
    { value: "emitir", label: "Emitir" },
    { value: "consultar", label: "Consultar" },
];

export const optionsActionInMassa = [
    {
        options: [
            { value: "", field:'', label: "Nenhum" },
        ],
    },
    {            
        label: "Forma pagamento",
        options: optionsTypePayment,
    },
    {
        label: "Situação da Parcela",
        options: optionsStatusParcel,
    },
];

export const optionsActionInMassaStatus = [
    {
        options: [
            { value: "", field:'', label: "Nenhum" },
        ],
    },
    {
        label: "Situação do Contrato",
        options: statusContract,
    },
];



// status da aparcela juducia
export const optionsStatusParcelHistory: Option[] = [
    { value: "processo_distrato", label: "Processo Distrato" },
    { value: "processo_judicial", label: "Processo Judicial" },
    { value: "vencido_renegociado", label: "Vencido/Renegociado" },
    { value: "vencido", label: "Vencido" },
    { value: "cancelado", label: "Cancelado" },
    { value: "distratado_com_acordo", label: "Distratado com acordo" },
    { value: "distratado_sem_acordo", label: "Distratado sem acordo" },
    { value: "protesto_em_cartorio", label: "Protesto em cartório" }
];

// recorrencia
export const optionsRecurrence: Option[] = [
    { value: "0", label: "Não" },
    { value: "1", label: "Sim" }
];

// periodicidade
export const optionsPeriodicidade: Option[] = [
    { value: "", label: "Nenhum" },
    { value: "mensal", label: "Mensal" },
    { value: "anual", label: "Anual" }
];

// frequencia
export const optionsFrequency: Option[] = [
    { value: "1D", label: "Diário" },
    { value: "1W", label: "Semanal" },
    { value: "15D", label: "Quizenal" },
    { value: "1M", label: "Mensal" },
    { value: "2M", label: "Bimestral" },
    { value: "3M", label: "Trimestral" },
    { value: "6M", label: "Semestral" },
    { value: "1Y", label: "Anual" }
];

// tipo parcela
export const optionsTypeParcel: Option[] = [
    { value: "AV", label: "Á vista" },
    { value: "CO", label: "Comissão Corretor" },
    { value: "COR", label: "Comissão Corretor (Repasse)" },
    { value: "CH", label: "Comissão Interna" },
    { value: "CHR", label: "Comissão Interna (Repasse)" },
    { value: "DS", label: "Desconto" },
    { value: "SI", label: "Entrada" },
    { value: "FG", label: "FGTS" },
    { value: "FB", label: "Financiamento Bancário" },
    { value: "IT", label: "Intermediaria"},
    { value: "PC", label: "Parcela de chaves"},
    { value: "PM", label: "Parcela Mensal" },
    { value: "AN", label: "Parcela Anual" },
    { value: "TR", label: "Parcela Trimestral" },
    { value: "SM", label: "Parcela Semestral" },
    { value: "SB", label: "Subsídio" }
];


// Indice de reajustes
export const optionsReadjustmentIndex: Option[] = [
    { value: "nenhum", label: "Nenhum" },
    { value: "IGPM", label: "IGPM" },
    { value: "IPCA", label: "IPCA" },
    { value: "INCC", label: "INCC" },
];

// Nota fiscal
export const optionsPossuiNotaFiscal: Option[] = [
    { value: "", label: "Todos" },
    { value: "sim", label: "Sim" },
    { value: "nao", label: "Não" },
];

// gerar parcelas de cobrança
export const optionsGenerateBillingInstallments: Option[] = [
    { value: "sim", label: "Sim" },
    { value: "nao", label: "Não" },
];

// tipo de ocnta
export const optionsTypeBank: Option[] = [
    { value: "001", label: "Corrente" },
    { value: "013", label: "Poupança" }
];

// lista de bancos
export const optionsBanks: Option[] = [
    { value: '',    label: ''},
    { value: '001', label: 'Banco do Brasil'},
    { value: '003', label: 'Banco da Amazônia'},
    { value: '004', label: 'Banco do Nordeste'},
    { value: '021', label: 'Banestes'},
    { value: '025', label: 'Banco Alfa'},
    { value: '027', label: 'Besc'},
    { value: '029', label: 'Banerj'},
    { value: '031', label: 'Banco Beg'},
    { value: '033', label: 'Banco Santander Banespa'},
    { value: '036', label: 'Banco Bem'},
    { value: '037', label: 'Banpará'},
    { value: '038', label: 'Banestado'},
    { value: '039', label: 'BEP'},
    { value: '040', label: 'Banco Cargill'},
    { value: '041', label: 'Banrisul'},
    { value: '044', label: 'BVA'},
    { value: '045', label: 'Banco Opportunity'},
    { value: '047', label: 'Banese'},
    { value: '062', label: 'Hipercard'},
    { value: '063', label: 'Ibibank'},
    { value: '065', label: 'Lemon Bank'},
    { value: '066', label: 'Banco Morgan Stanley Dean Witter'},
    { value: '069', label: 'BPN Brasil'},
    { value: '070', label: 'Banco de Brasília – BRB'},
    { value: '072', label: 'Banco Rural'},
    { value: '073', label: 'Banco Popular'},
    { value: '074', label: 'Banco J. Safra'},
    { value: '075', label: 'Banco CR2'},
    { value: '076', label: 'Banco KDB'},
    { value: '077', label: 'Banco Inter'},
    { value: '096', label: 'Banco BMF'},
    { value: '104', label: 'Caixa Econômica Federal'},
    { value: '107', label: 'Banco BBM'},
    { value: '116', label: 'Banco Único'},
    { value: '151', label: 'Nossa Caixa'},
    { value: '175', label: 'Banco Finasa'},
    { value: '184', label: 'Banco Itaú BBA'},
    { value: '197', label: 'Stone Pagamentos S.A.'},
    { value: '204', label: 'American Express Bank'},
    { value: '208', label: 'Banco Pactual'},
    { value: '212', label: 'Banco Original'},
    { value: '213', label: 'Banco Arbi'},
    { value: '214', label: 'Banco Dibens'},
    { value: '217', label: 'Banco Joh Deere'},
    { value: '218', label: 'Banco Bonsucesso'},
    { value: '222', label: 'Banco Calyon Brasil'},
    { value: '224', label: 'Banco Fibra'},
    { value: '225', label: 'Banco Brascan'},
    { value: '229', label: 'Banco Cruzeiro'},
    { value: '230', label: 'Unicard'},
    { value: '233', label: 'Banco GE Capital'},
    { value: '237', label: 'Bradesco'},
    { value: '241', label: 'Banco Clássico'},
    { value: '243', label: 'Banco Stock Máxima'},
    { value: '246', label: 'Banco ABC Brasil'},
    { value: '248', label: 'Banco Boavista Interatlântico'},
    { value: '249', label: 'Investcred Unibanco'},
    { value: '250', label: 'Banco Schahin'},
    { value: '252', label: 'Fininvest'},
    { value: '254', label: 'Paraná Banco'},
    { value: '260', label: 'Nubank'},
    { value: '263', label: 'Banco Cacique'},
    { value: '265', label: 'Banco Fator'},
    { value: '266', label: 'Banco Cédula'},
    { value: '290', label: 'PagBank (Pagseguro)'},
    { value: '300', label: 'Banco de la Nación Argentina'},
    { value: '318', label: 'Banco BMG'},
    { value: '320', label: 'Banco Industrial e Comercial'},
    { value: '336', label: 'Banco C6 S.A – C6 Bank'},
    { value: '356', label: 'ABN Amro Real'},
    { value: '341', label: 'Itau'},
    { value: '347', label: 'Sudameris'},
    { value: '351', label: 'Banco Santander'},
    { value: '353', label: 'Banco Santander Brasil'},
    { value: '366', label: 'Banco Societe Generale Brasil'},
    { value: '370', label: 'Banco WestLB'},
    { value: '376', label: 'JP Morgan'},
    { value: '389', label: 'Banco Mercantil do Brasil'},
    { value: '394', label: 'Banco Mercantil de Crédito'},
    { value: '399', label: 'HSBC'},
    { value: '403', label: 'Cora SCD S.A'},
    { value: '409', label: 'Unibanco'},
    { value: '412', label: 'Banco Capital'},
    { value: '422', label: 'Banco Safra'},
    { value: '453', label: 'Banco Rural'},
    { value: '456', label: 'Banco Tokyo Mitsubishi UFJ'},
    { value: '464', label: 'Banco Sumitomo Mitsui Brasileiro'},
    { value: '477', label: 'Citibank'},
    { value: '479', label: 'Itaubank (antigo Bank Boston)'},
    { value: '487', label: 'Deutsche Bank'},
    { value: '488', label: 'Banco Morgan Guaranty'},
    { value: '492', label: 'Banco NMB Postbank'},
    { value: '494', label: 'Banco la República Oriental del Uruguay'},
    { value: '495', label: 'Banco La Provincia de Buenos Aires'},
    { value: '505', label: 'Banco Credit Suisse'},
    { value: '600', label: 'Banco Luso Brasileiro'},
    { value: '604', label: 'Banco Industrial'},
    { value: '610', label: 'Banco VR'},
    { value: '611', label: 'Banco Paulista'},
    { value: '612', label: 'Banco Guanabara'},
    { value: '613', label: 'Banco Pecunia'},
    { value: '623', label: 'Banco Pan'},
    { value: '626', label: 'Banco Ficsa'},
    { value: '630', label: 'Banco Intercap'},
    { value: '633', label: 'Banco Rendimento'},
    { value: '634', label: 'Banco Triângulo'},
    { value: '637', label: 'Banco Sofisa'},
    { value: '638', label: 'Banco Prosper'},
    { value: '643', label: 'Banco Pine'},
    { value: '652', label: 'Itaú Holding Financeira'},
    { value: '653', label: 'Banco Indusval'},
    { value: '654', label: 'Banco A.J. Renner'},
    { value: '655', label: 'Banco Votorantim'},
    { value: '707', label: 'Banco Daycoval'},
    { value: '719', label: 'Banif'},
    { value: '721', label: 'Banco Credibel'},
    { value: '734', label: 'Banco Gerdau'},
    { value: '735', label: 'Banco Pottencial'},
    { value: '738', label: 'Banco Morada'},
    { value: '739', label: 'Banco Galvão de Negócios'},
    { value: '740', label: 'Banco Barclays'},
    { value: '741', label: 'BRP'},
    { value: '743', label: 'Banco Semear'},
    { value: '745', label: 'Banco Citibank'},
    { value: '746', label: 'Banco Modal'},
    { value: '747', label: 'Banco Rabobank International'},
    { value: '748', label: 'Banco Cooperativo Sicredi'},
    { value: '749', label: 'Banco Simples'},
    { value: '751', label: 'Dresdner Bank'},
    { value: '752', label: 'BNP Paribas'},
    { value: '753', label: 'Banco Comercial Uruguai'},
    { value: '755', label: 'Banco Merrill Lynch'},
    { value: '756', label: 'Banco Cooperativo do Brasil - Sicoob'},
    { value: '757', label: 'KEB'}
];

// recorrencia
export const optionsUsers: Option[] = [
    { value: "superadmin", label: "Root" },
    { value: "admin", label: "Administrador" },
    { value: "user", label: "Apenas Revisor" },
];