import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFilterState {
    [key: string]: any;
}

const initialState: IFilterState = {};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<IFilterState>
        ) => {

            Object.keys(action.payload).forEach((key) => {
                state[key] = action.payload[key];
            });
        },
    },
});

export const { setFilter } = filterSlice.actions;
export default filterSlice.reducer;
