import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../auth/getToken';

const API_URL = process.env.REACT_APP_API_URL;
const tokenLocal = getToken()?.token;

export const billingPosition = createApi({
    reducerPath: 'billingPosition',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers) => {
            const dataLocalStorage = getToken();
            if (!headers.has("Authorization")) {
                if (dataLocalStorage?.token) {
                    headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
                }
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchBillingPosition: builder.query({
            query: (params: any) => ({
                url: `/module/billingPosition`,
                method: 'POST',
                body: { filtro: params, token: getToken()?.token },
                headers: {
                    Authorization: `Bearer ${tokenLocal}`,
                },
            }),
        }),
    }),
});

export const { useFetchBillingPositionQuery } = billingPosition;